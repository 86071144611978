@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: lighter;
    src: url('fonts/HelveticaNeueLTPro-Lt.otf');
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: lighter;
    font-stretch: condensed;
    src: url('fonts/HelveticaNeueLTPro-LtCn.otf');
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: 200;
    src: url('fonts/HelveticaNeueLTPro-Lt.otf');
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: 200;
    font-stretch: condensed;
    src: url('fonts/HelveticaNeueLTPro-LtCn.otf');
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: normal;
    src: url('fonts/HelveticaNeueLTPro-Roman.otf');
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: 400;
    src: url('fonts/HelveticaNeueLTPro-Roman.otf');
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: 500;
    src: url('fonts/HelveticaNeueLTPro-Md.otf');
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: 500;
    font-stretch: condensed;
    src: url('fonts/HelveticaNeueLTPro-MdCn.otf');
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: bold;
    src: url('fonts/HelveticaNeueLTPro-Bd.otf');
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: bold;
    font-stretch: condensed;
    src: url('fonts/HelveticaNeueLTPro-BdCn.otf');
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: 700;
    src: url('fonts/HelveticaNeueLTPro-Bd.otf');
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: 700;
    font-stretch: condensed;
    src: url('fonts/HelveticaNeueLTPro-BdCn.otf');
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: 800;
    src: url('fonts/HelveticaNeueLTPro-Hv.otf');
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: 900;
    src: url('fonts/HelveticaNeueLTPro-Hv.otf');
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Pro";
    font-weight: bolder;
    src: url('fonts/HelveticaNeueLTPro-Hv.otf');
    font-display: swap;
}