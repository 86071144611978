@tailwind base;
@tailwind components;
// Code syntax highlighting,
// powered by https://highlightjs.org
@import "~cookie-consent-js/src/cookie-consent.scss";
@import '~highlight.js/styles/a11y-light.css';
@import 'base';
@import 'navigation';
@import 'mailchimp';
@import 'blog';
@import 'style';
@import '../css/YouTubePopUp.css';
//@import 'node_modules/font-awesome/scss/font-awesome.scss';
@import '/assets/fonts/font-awesome/style.css';
@import '/assets/fonts/HelveticaNeueLTPro/style.css';
@import "~aos/dist/aos.css";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@tailwind utilities;