@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?b1f2yi');
    src: url('fonts/icomoon.eot?b1f2yi#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?b1f2yi') format('truetype'), url('fonts/icomoon.woff?b1f2yi') format('woff'), url('fonts/icomoon.svg?b1f2yi#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-youtube:before {
  content: "\e90d";
}
.icon-apostrof:before {
  content: "\e90a";
  color: #73be46;
}
.icon-przewaga:before {
  content: "\e90c";
}
.icon-arrow-up:before {
  content: "\e90b";
}
.icon-no:before {
  content: "\e908";
}
.icon-arrow-down:before {
  content: "\e906";
}
.icon-arraw-left:before {
  content: "\e900";
}
.icon-envelope:before {
  content: "\e909";
}
.icon-phone:before {
  content: "\e904";
}
.icon-arraw-down-litle:before {
  content: "\e905";
}
.icon-arrow-circle-down:before {
  content: "\e907";
}
.icon-checked:before {
  content: "\e903";
}
.icon-map-pin:before {
  content: "\e901";
}
.icon-calculator:before {
  content: "\e902";
}
.icon-quotes-left:before {
  content: "\e977";
}
.icon-infinite:before {
  content: "\ea2f";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-youtube2:before {
  content: "\ea9e";
}
.icon-linkedin:before {
  content: "\eac9";
}
.icon-linkedin2:before {
  content: "\eaca";
}
