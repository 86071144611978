:root {
    --text-color-black: #545566;
    --text-color-black2: #272838;
    --color-blue: #036db6;
    --color-green: #72be44;
    --color-blue2: #0081d9;
    --font09: 0.9rem;
    --font12: 1.2rem;
    --font15: 1rem;
    --font17: 1.1rem;
    --font20: 2rem;
    --font22: 2.2rem;
    --font35: 3.5rem;
    --font75: 4rem;
    --font-first-col: .9rem;
    --font-first-col-sub: .7rem;
    --margin-container: 0;
}

html {
    scroll-behavior: smooth;
}

p,
li {
    font-weight: lighter;
    color: var(--text-color-black);
}

a {
    color: var(--color-blue);
}

a,
.button {
    cursor: pointer;
}

footer {
    z-index: 1;
    .b24-form-control-label,
    .b24-form-control-desc,
    .b24-form-btn {
        font: lighter 18px/18px "Helvetica Neue LT Pro", sans-serif !important;
        opacity: 1 !important;
    }
}

main {
    z-index: 1;
}

.copyright {
    font-weight: lighter;
    text-transform: uppercase;
    color: #56616b;
}

#sectionSeparatorText {
    --font-size: 6rem;
    p {
        color: #e5eaee;
        position: relative;
        font-size: var(--font75);
        font-weight: 800;
        text-transform: lowercase;
        line-height: .9;
    }
    span {
        position: relative;
        bottom: 2em;
        font-size: .9rem;
    }
    p span img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: calc(1.5 * var(--font-size));
        height: 100%;
    }
}

section {
    .header,
    header {
        p {
            color: #f1f4f6;
            position: relative;
            font-size: var(--font75);
            font-weight: 800;
            text-transform: lowercase;
            line-height: .9;
        }
        >span {
            position: relative;
            display: block;
            top: -1em;
            font-size: var(--font22);
            color: var(--color-blue);
            font-weight: 500;
            font-stretch: condensed;
            line-height: 1;
        }
    }
    .header.center,
    header.center {
        height: 100%;
        position: relative;
        margin: 2rem 0;
        // span {
        //     width: 100%;
        //     position: absolute;
        //     align-items: center;
        //     top: 45%;
        // }
    }
}

.call p {
    font-size: 1em;
    font-stretch: condensed;
    span {
        position: absolute;
        top: 0;
        right: 20%;
        white-space: nowrap;
        font-size: 0.875rem;
        text-transform: lowercase;
    }
    i {
        vertical-align: bottom;
        display: inline;
        width: 2em;
        font-size: 2rem;
        color: var(--color-green);
    }
    a {
        font-weight: bold;
        font-size: 1rem;
    }
}

#address {
    color: var(--color-blue);
    font-weight: 700;
    i {
        color: var(--color-green);
        margin: 0 1rem;
        font-size: 2rem;
    }
    p img {
        vertical-align: bottom;
        display: inline;
        width: 2em;
    }
}

.sectionPresentation {
    >div {
        display: flex;
        flex-wrap: wrap;
        //  
        line-height: 1.2;
    }
    i {
        color: var(--color-green);
        font-size: var(--font35);
        margin-right: 1rem;
    }
    div span {
        display: flex;
        font-weight: lighter;
    }
    div a:not(.presentation) {
        display: flex;
        font-weight: bold;
        font-stretch: condensed;
        font-size: var(--font17);
        justify-content: space-between;
    }
}

.presentation {
    color: #ffffff;
    justify-content: center;
    cursor: pointer;
    h2 {
        color: #ffffff;
        font-size: var(--font22);
        font-stretch: condensed;
    }
    div:last-child {
        position: absolute;
        margin-left: 1rem;
    }
    a {
        color: white;
        font-weight: normal;
    }
    i {
        color: white;
        font-weight: bold;
        font-size: 1rem;
        align-items: center;
        display: flex;
    }
    a:hover {
        font-weight: bold;
    }
}

.contact {
    a {
        font-weight: normal;
    }
    p {
        color: var(--color-blue);
    }
    h2 {
        font-weight: lighter;
        font-size: var(--font22);
    }
    #company {
        text-align: left;
        font-stretch: condensed;
        ul {
            display: flex;
            list-style: none;
            font-size: 0.9rem;
        }
        ul>li {
            text-align: left;
            font-weight: normal;
        }
        li::before {
            content: "•";
            padding: 0 4px;
            margin: 0 4px;
            color: var(--color-blue);
        }
        li:first-child::before {
            content: none;
        }
    }
    #address p {
        display: flex;
        align-content: center;
        font-weight: bold;
        font-size: var(--font09);
        a {
            font-weight: bold;
        }
    }
}

i .fa {
    padding-right: 2%;
    color: forestgreen;
}

.more-list {
    position: relative;
    background: white;
    display: none;
    z-index: 1;
    ul {
        list-style: none;
        position: absolute;
        background: white;
        margin: auto;
        padding-right: 2rem;
        padding-bottom: 1rem;
    }
}

footer {
    .save:hover {
        background-color: var(--color-green);
        div:first-child {
            background-color: var(--color-green);
        }
        a.presentation:hover {
            font-weight: 700;
            color: #fff;
            font-size: var(--font17);
            z-index: 1;
            i {
                font-size: var(--font09);
                font-weight: bold;
                color: white;
            }
        }
    }
    .save {
        text-transform: lowercase;
        background-color: var(--color-blue);
        position: relative;
        div:first-child {
            background-image: url('/assets/img/contact.webp');
            background-color: var(--color-blue);
            background-size: cover;
            background-position-y: 20%;
            background-blend-mode: multiply;
            opacity: 65%;
            position: absolute;
            width: 100%;
            height: 100%;
        }
        a.presentation {
            font-weight: 700;
            color: #fff;
            font-size: var(--font17);
            z-index: 1;
            i {
                font-size: var(--font09);
                font-weight: bold;
                color: white;
            }
        }
    }
    .contact-form {
        background-color: var(--color-blue);
        color: white;
        background-image: url(/assets/img/footer-bg.png);
        background-repeat: no-repeat;
        background-position: right;
        >div>span {
            display: block;
            font-size: var(--font12);
        }
        div.b24-form-sign span {
            color: var(--color-blue);
        }
        .b24-form-control-container {
            display: inline-flex !important;
            font-weight: lighter;
        }
        .b24-form-field-agreement .b24-form-field-agreement-link {
            font-size: 0.9rem !important;
            padding: 0 10px;
        }
        button.b24-form-btn {
            box-shadow: none;
            border-bottom: 2px solid white;
            border-radius: 0;
            text-transform: uppercase;
        }
    }
    .contact-container::before {
        content: "";
        border-bottom: 4em solid var(--color-blue);
        border-left: 4em solid transparent;
        display: block;
        margin-top: -0.05rem;
        background-image: url(/assets/img/footer-bg.png);
        background-repeat: no-repeat;
        background-position: right;
    }
}

header .social-set {
    list-style: none;
    display: flex;
    margin: auto;
    margin-top: 0;
    li {
        padding: .4rem;
    }
    .sociali {
        border: 1px solid;
        border-radius: 100%;
        padding: 0.5rem 0.67em;
    }
    a.sociali:hover {
        color: white;
    }
}

main .social {
    padding: 2rem;
    display: flex;
    margin: auto;
    font-size: var(--font22);
    .sociali {
        padding: 1rem;
        border-radius: 10%;
    }
    a.sociali:hover {
        color: white;
    }
}

.yt {
    border-color: red;
    color: red;
}

a.yt:hover {
    background-color: red;
}

.fb {
    border-color: #3B5998;
    color: #3B5998;
}

a.fb:hover {
    background-color: #3B5998;
}

.in {
    border-color: #0E76A8;
    color: #0E76A8;
}

a.in:hover {
    background-color: #0E76A8;
}

.slider-page {
    width: 2px;
    height: 80%;
    img {
        width: 100%;
        height: 100%;
    }
    >div:first-child {
        height: 100%;
        margin-left: 6px;
        width: 100%;
    }
    .visit {
        background-color: var(--color-blue);
        height: 25%;
    }
    .point {
        color: var(--color-green);
        font-size: 1.5rem;
        line-height: .7;
        margin-left: -.3rem;
    }
    .no-visit {
        background-color: #e2e8f0;
        height: 75%;
    }
}

.more {
    margin: auto auto 0 2rem;
    text-transform: uppercase;
    height: 80%;
    z-index: 10;
    a {
        color: #000000;
        display: flex;
        align-items: center;
        width: max-content;
        padding-top: 2rem;
        font-weight: 500;
        i {
            font-weight: 500;
            font-size: 1.5rem;
            padding: 0 2rem 0 0;
        }
    }
}

.title-page,
.title-blog {
    --size-title: 3rem;
    padding: var(--size-title) var(--size-title);
    background-color: var(--color-blue);
    background: url('/assets/img/header-bg.webp') no-repeat;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    height: 100%;
    font-stretch: condensed;
    position: relative;
    h1,
    h2 {
        font-weight: bold;
        color: white;
    }
    h2 {
        font-size: var(--size-title);
    }
    .description {
        font-size: calc(var(--size-title)/2);
        font-weight: lighter;
        color: white;
         :nth-child(n) {
            color: white;
            font-size: calc(var(--size-title)/2);
            font-weight: lighter;
        }
    }
    span {
        display: block;
    }
    .header-content {
        border: 10px solid white;
        border-image: url('/assets/img/header-border.png') 1;
        z-index: 1;
    }
    .title-header {
        justify-content: center;
        align-items: center;
    }
    .background {
        div {
            position: absolute;
        }
        div:first-child {
            top: 0;
            left: 0;
            height: 100%;
            img {
                height: 100%;
            }
        }
        div:last-child {
            top: var(--size-title);
            right: 0;
            img {
                margin: auto;
            }
        }
    }
    .bg-section {
        position: absolute;
        right: 0;
        width: 25vw;
        top: calc(100% - var(--size-title)*3);
        img {
            position: relative;
            width: 100%;
        }
    }
}

.title-blog {
    .bg-section {
        width: 20vw;
    }
}

.bg-size {
    background-size: 200% 150%;
}

.c-blue1 {
    color: #7ca8c5;
}

.c-blue2 {
    color: #c5e0f1;
}

.image {
    position: relative;
    width: 70%;
}

.image img {
    border-radius: 10px;
}

.praca-zorganizowana {
    >div:last-child {
        position: relative;
    }
    >div:last-child>img {
        position: absolute;
        top: -9rem;
        right: 0;
        max-width: none;
        width: 30vw;
    }
    >div:first-child {
        background: url('/assets/img/praca_margin.webp') no-repeat;
        background-position-x: 68%;
        mask-image: url(/assets/img/praca_margin.svg);
        mask-size: cover;
        mask-repeat: no-repeat;
        mask-position: right;
    }
    header {
        text-align: left;
        text-transform: none;
    }
    .zus-panel img {
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.33);
        border-radius: 10px;
        transform: scale(1.1);
        z-index: 10;
    }
    .czas p {
        padding-right: 2rem;
    }
}

.button {
    border-radius: 10px;
    background-color: var(--color-green);
    color: #ffffff;
    text-transform: uppercase;
    padding: 1rem 2.5rem;
    display: inline;
    font-size: (var(--size-title)/2);
    font-weight: bold;
}

.button:hover {
    color: #ffffff;
}

.advChek {
    div {
        border: 1px solid #e9ecee;
        padding: 1rem;
    }
    >div:hover {
        box-shadow: 0 0 20px 10px #e1f0fb;
        z-index: 10;
        h2 a,
        h3 {
            color: var(--color-green);
        }
    }
    h2 a {
        color: var(--color-blue);
        font-size: 1.8rem;
    }
    h3 {
        color: var(--color-blue2);
        font-size: 1.8rem;
    }
    h3::before {
        content: "\e903";
        font-size: 3rem;
        font-family: "icomoon";
        display: block;
        padding: 1rem 0;
    }
}

header .active {
    border-top: 1px solid var(--color-blue);
    a {
        color: var(--color-blue);
    }
}

header .active::before {
    content: '\e905';
    font-family: 'icomoon';
    color: var(--color-blue);
    display: block;
    margin-top: -2px;
    font-size: 8px;
    position: relative;
    height: 0;
}

.active h3 {
    color: var(--color-green);
}

.ask-contact:hover {
    box-shadow: 0 0 20px 10px #e1f0fb;
    background-color: var(--color-green);
}

.ask-contact {
    background-color: var(--color-blue2);
    background-image: url(/assets/img/ask-contact-bg.png);
    background-repeat: no-repeat;
    background-position: bottom;
    color: white;
    font-size: var(--font12);
    font-weight: 100;
    display: flex;
    justify-content: space-around;
    p {
        color: white;
        font-weight: normal;
        display: flex;
        justify-content: space-around;
        white-space: nowrap;
    }
    div {
        border: none;
        padding: 0%;
        span {
            font-weight: bold;
            display: block;
        }
    }
    i {
        font-size: var(--font22);
        color: white;
        padding-left: 10px;
        align-items: center;
    }
    a {
        color: white;
        font-size: var(--font12);
    }
}

.profit {
    article {
        padding: 0;
        display: flex;
        h3 {
            color: var(--color-blue2);
            font-weight: bold;
            text-align: left;
        }
        p {
            margin: auto;
            display: flex;
        }
        div:first-child {
            width: 100%;
            img {
                margin: auto;
            }
        }
        div::before {
            float: left;
            display: flex;
            padding-right: 1rem;
        }
    }
    article:nth-child(n) {
        border: solid transparent;
        border-width: 4px;
    }
    article:nth-child(n) {
        border-image: 0 3 0 0 repeat url(/assets/img/adv-border.png);
        border-image-outset: 1;
    }
    article:nth-child(n)::after {
        content: "\2022";
        color: white;
        background: white;
        border-radius: 50%;
        border: 7px solid var(--color-blue);
        box-shadow: 0cm 0cm 0.7408333333cm 0cm #4ff5ff;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        margin: 0 1rem;
        position: relative;
        right: -2rem;
    }
}

.about-us {
    margin: 0 var(--margin-container) 0 0;
    color: #172129;
    h3 {
        color: #172129;
    }
    .header {
        position: relative;
    }
    .doradca {
        position: relative;
        height: fit-content;
        div:last-child {
            padding-left: 1rem;
            margin-left: auto;
        }
        div:last-child {
            font-stretch: condensed;
            margin-top: 2rem;
            position: absolute;
            right: 0%;
            bottom: 0%;
            display: flex;
            flex-direction: column;
        }
        div {
            >p {
                text-transform: uppercase;
                font-weight: inherit;
                margin: 0;
                span {
                    display: block;
                }
            }
        }
        >div:first-child {
            height: 100%;
            >img {
                width: 110%;
                max-height: 100%;
                position: relative;
                left: -2rem;
                mask-image: url(/assets/img/jr-svg.svg);
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;
            }
        }
    }
    .poznajmy {
        header p {
            display: inline;
            font-weight: normal;
        }
        p>span {
            font-weight: normal;
        }
    }
}

.liczniki {
    font-stretch: condensed;
    font-weight: lighter;
    span {
        display: block;
    }
    .value {
        font-weight: bold;
        line-height: 1;
        font-size: var(--font12);
        .count,
        .count2 {
            font-size: calc(2.4 * var(--font12));
        }
    }
    >div>div {
        border-top: 1px solid #949697;
    }
    >div>div::before {
        content: '\e905';
        font-family: 'icomoon';
        color: var(--color-blue);
        padding-bottom: 1rem;
        display: block;
        margin-top: -5px;
    }
}

.c-blue {
    color: var(--color-blue);
}

.c-green {
    color: var(--color-green);
}

.c-red {
    color: #f31c1c;
}

.opinie header {
    font-stretch: condensed;
    font-weight: lighter;
    font-size: 2.25rem;
    text-transform: lowercase;
}

.opinion-arrows {
    font-size: 5rem;
}

.icon-apostrof:hover {
    color: #869180;
}

.slider {
    padding: 2rem;
}

.slick-dots {
    position: static !important;
    font-size: 2rem;
    display: inline-flex !important;
}

.slick-dots li button:before {
    font-size: 1.5rem !important;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    color: var(--color-green) !important;
}

.slick-dots li.slick-active button:before {
    color: var(--color-green) !important;
}

.scroll-to-top {
    height: 0;
    z-index: 10;
    a {
        font-size: 1.5rem;
        position: fixed;
        bottom: 2rem;
        display: none;
    }
    >div:first-child {
        position: relative;
    }
    hr {
        border-color: #e2e8f0;
    }
}

.scroll-to-top:hover {
    opacity: 1;
}

.header-circle {
    --size: var(--font22);
    --size-line-height: 1.5;
    font-size: var(--size);
    font-weight: lighter;
    text-transform: none;
    margin: 0;
    color: #172129;
}

.header-circle>span {
    position: static;
    font-weight: bold;
    padding: 0;
    display: block;
}

span.circle {
    color: white;
    border-radius: 50%;
    background-color: var(--color-blue);
    height: calc(var(--size)*2);
    width: calc(var(--size)*2);
    display: inline-block;
    text-align: left;
    line-height: var(--size-line-height);
}

.hd-white {
    color: white;
    >span {
        color: white;
        >span {
            color: var(--color-blue);
            background-color: white;
        }
    }
}

.hd-green>span>span {
    background-color: var(--color-green);
    line-height: 1.8;
}

.przewaga {
    div.image {
        position: relative;
        img {
            position: absolute;
            right: 0;
            height: fit-content;
            max-width: 200%;
        }
    }
    article {
        padding-bottom: 1rem;
        padding-right: 1rem;
    }
    article:nth-child(n) {
        line-height: 2;
        font-weight: 100;
    }
}

.panel {
    >div::before {
        content: "\e902";
        font-family: 'icomoon';
        float: left;
        display: block;
        font-size: 2rem;
        padding: 0 1rem;
        color: var(--color-blue);
    }
    article {
        padding: 1rem 2rem;
    }
}

.info>header {
    padding: 4rem 0 2rem 0;
}

article {
    header,
    h3 {
        display: block;
        color: var(--color-blue);
        font-size: var(--font12);
        font-weight: bold;
        width: 100%;
    }
    ul {
        list-style: none;
        li {
            display: flex;
            padding: .7rem;
        }
        li img {
            height: 100%;
            margin: .8em .5em;
        }
        li::before {
            content: "\2022";
            color: var(--color-blue);
            font-weight: bold;
            display: inline-block;
            font-size: 2rem;
            line-height: .9;
        }
    }
}

//cennik
.order div {
    border: none;
}

.order-btn a {
    color: white;
    padding: 1.2rem;
    display: block;
    text-transform: uppercase;
    font-weight: 900;
}

.cennik {
    --padding-x: .2rem;
    --padding-y: .5rem;
    display: flex;
    flex-direction: column;
    div.row {
        display: flex;
    }
    .header-row:hover {
        .description {
            display: block;
        }
        .period>span {
            display: inline;
        }
    }
    .header-row {
        >div:first-child{
            border: none;
        }
        .bg-info {
            color: #6f8593;
            background: #f2f5f7;
            font-size: var(--font12);
        }
        .head {
            padding: var(--padding-y) 0;
            font-stretch: condensed;
            h3 {
                color: white;
                font-size: var(--font15);
                margin: 0;
                text-align: center;
            }
            .description {
                font-weight: 100;
            }
            >div {
                align-items: center;
                text-align: center;
                .price {
                    font-size: var(--font12);
                    font-weight: bold;
                }
                .period {
                    font-weight: normal;
                    padding: 0 5px;
                    font-size: var(--font09);
                }
            }
        }
        .bg-max {
            background: #009deb;
            color: white;
            padding-left: 10px;
        }     
        .bg-st-p {
            background: #0090e9;
            color: white;
        }
        .bg-st {
            background: #008ce2;
            color: white;
        }   
        .bg-mini {
            background: #007bd0;
            color: white;
        }
        .bg-start-p {
            background: #0070c0;
            color: white;
        }
        .bg-start {
            background: #0067b1;
            color: white;
        }
    }
    .row {
        .bg-info-old{
            align-items: center;
        }
        .bg-info{
            width: 100%;
            align-items: center;
        }
        >:first-child {
            min-width: 20%;
            text-align: left;
            font-stretch: condensed;
            justify-content: center;
        }
        >div {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: var(--padding-y) var(--padding-x);
            border: 1px solid #eaeaea;
            font-size: var(--font09);
            width: 26%;
        }
    }
    .row.footer {
        >:first-child {
            width: 20%;
            border: none;
        }
        >div {
            width: 80%;
        }
    }
    .group{
        .row.function {
            &.show{
                >:first-child .tooltip {
                    &::after{
                        content:"\0025B2";
                    }
                }
            }
            >:first-child .tooltip{
                &::after{
                    content:"\0025BC";
                    color: var(--color-blue);
                }
                .tooltiptext{
                    white-space: normal;
                    width: 30vw;
                    &::after {
                        content: "";
                        left: 10%;
                    }
                }
            }
            &.sub >:first-child {
                &::after{
                    content:"";
                }
            }
        }
    }
    .row.function {
        &:hover {
            background-color: #f2f5f7;
            >div {
                background-color: #f2f5f7;
            }
        }
        >:first-child {
            text-align: left;
            align-items: flex-start;    
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            h2 {
                font-size: var(--font-first-col);
                color: var(--text-color-black2);
                font-weight: normal;
                margin: 0;
                span {
                    font-weight: bold;
                    color: var(--color-blue2);
                }
            }
        }
        >div {
            justify-content: center;
            text-align: center;
            color: #007bd0;
            background-color: white;
        }
        i {
            border-radius: 50%;
            padding: 0.5em;
            display: inline-block;
        }
        span {
            line-height: 2;
        }
        i.c-green {
            background-color: #e6f0e0;
        }
        i.c-red {
            background-color: #f0e0e0;
        }
        i.c-blue {
            background-color: #c5e0f1;
        }
        &.sub{
            display: none;
            &.show{
                display: flex;
            }
            >:first-child{
                justify-content: flex-end;
                &::after{
                    content:"";
                }
            } h2{
               text-align: right;
               font-size: var(--font-first-col-sub);
            }
            >div{
                padding: calc(var(--padding-y) * 0.5) var(--padding-x);
            }
        }
    }

    .tooltip{
        border: none;
        .tooltiptext{
            white-space: nowrap;
            width: auto;
            padding: 5px;
        }
    }
}

.stars {
    font-size: var(--font-first-col);
    color: var(--color-blue);
    font-weight: lighter;
    font-stretch: condensed;
    font-style: italic;
    span {
        font-weight: 500;
    }
}

//end cennik
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        top: 125%;
        left: 50%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
    }
    .tooltiptext::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #555 transparent;
        ;
    }
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

@media (min-width: 640px) {
    section .header.center span,
    section header.center span {
        width: 100%;
        position: absolute;
        align-items: center;
        top: 45%;
    }
    .title-page h1 {
        white-space: nowrap;
    }
    .background::before {
        content: url(/assets/img/elipse-top.png);
        position: absolute;
        right: 0;
        top: calc(-.2*var(--size-title));
    }
    .background::after {
        content: url(/assets/img/title-page-dot-down.png);
        position: absolute;
        right: calc(var(--size-title)*1.5);
        bottom: calc(var(--size-title));
        z-index: 10;
    }
    .bg-size .background::after {
        bottom: 0;
    }
    .image img {
        max-width: 100%;
        float: right;
        margin-right: 0;
    }
    .header-row {
        .period span>span {
            white-space: nowrap;
        }
    }
}

@media (min-width: 1024px) {
     :root {
        --margin-container: 15vw;
        --font09: .9vw;
        --font12: 1.2vw;
        --font15: 1.5vw;
        --font17: 1.7vw;
        --font20: 2vw;
        --font22: 2.2vw;
        --font35: 3.5vw;
        --font75: 7.57vw;
        --font-first-col: 1.2vw;
        --font-first-col-sub: 1vw;
    }
    .container {
        width: calc(100vw - var(--margin-container)*2);
        max-width: none;
    }
    #sectionSeparatorText {
        span {
            font-size: 1.35rem;
        }
    }
    section {
        header>span {
            line-height: 1.2;
            padding: 0 2rem;
        }
        .header>span {
            top: -6rem;
            width: 100%;
        }
    }
    .call p {
        padding: 0;
        span {
            right: 0;
        }
        a {
            font-size: 1.5rem;
        }
    }
    .title-page {
        padding: var(--size-title) calc(var(--size-title)*2) calc(var(--size-title)) 0;
        .image img {
            --margin-img: 20vw;
            max-width: calc(100% + var(--margin-img));
            float: right;
            margin-right: var(--margin-img);
        }
    }
    .title-page::before {
        content: url(/assets/img/title-page-dot-up.png);
        margin-right: 8vw;
        width: 0;
        position: relative;
        top: calc(-1*var(--size-title));
    }
    .praca-zorganizowana {
        .czas::before {
            float: none;
            margin-right: 1rem;
        }
        .skroc-proces::before {
            float: none;
            margin-right: 1rem;
        }
    }
    .info {
        article {
            padding: 2rem 0;
        }
        article:last-child {
            display: flex;
            flex-wrap: wrap;
            ul {
                width: 50%;
            }
        }
    }
    .contact-container {
        width: 55% !important;
        .contact-form {
            font-size: 2rem;
            font-weight: 600;
            .b24-form-content>form,
            .b24-form-content>form>div:first-child {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            .b24-form-field-name,
            .b24-form-field-last-name,
            .b24-form-field-email {
                width: 30%;
            }
            .b24-form-field-text {
                width: 100%;
            }
            .b24-form-content>form>div.b24-form-btn-container {
                width: 30%;
            }
            .b24-form-content>form>div {
                width: 70%;
            }
            .b24-form-content>form>div:first-child {
                width: 100%;
            }
        }
    }
    .contact {
        width: 45% !important;
        h2 {
            text-align: left;
        }
    }
    .about-us .doradca {
        background: url(/assets/img/about-us-bg.svg) no-repeat;
        background-size: contain;
        background-position: bottom;
        div:last-child {
            padding-left: 1rem;
        }
        div:last-child {
            right: 5%;
            bottom: 5%;
        }
    }
    .header-circle {
        --size-line-height: 1.7;
    }
    .przewaga {
        article:first-child {
            padding-right: 3rem;
        }
        .przewaga article:last-child {
            padding-left: 1rem;
        }
    }
    .poznajmy {
        div p {
            padding: 0;
        }
    }
    .panel article {
        padding: 4rem 4rem 4rem 2rem;
    }
    .hr {
        margin: 0;
        width: calc(100% + var(--margin-container));
        border-bottom: 1px solid #dddddd;
    }
    .liczniki {
        .value {
            font-weight: normal;
        }
        .value>div {
            padding-left: .5rem;
        }
        >div>div {
            border-top: none;
        }
        >div>div::before {
            padding-bottom: 3rem;
            margin-top: -5px;
        }
    }
    .section-present>div {
        clear: both;
        background-image: url('/assets/img/saved-border.png');
        background-position: left;
        background-repeat: no-repeat;
        background-size: 90% 80%;
        margin-left: 3vw;
        padding-left: 2vw;
    }
    .sectionPresentation {
        margin-left: 2rem;
        .presentation {
            justify-content: center;
            div span {
                display: flex;
                font-weight: lighter;
                font-size: var(--font12);
            }
        }
    }
    .advChek div {
        padding: 2rem;
    }
    .profit {
        article {
            width: 50%;
        }
        article:nth-child(2n+1) {
            border-image: 0 0 0 3 repeat url(/assets/img/adv-border.png);
            margin-left: 50%;
        }
        article:nth-child(2n)::after {
            content: "\2022";
            color: white;
            background: white;
            border-radius: 50%;
            border: 7px solid var(--color-blue);
            box-shadow: 0cm 0cm 0.7408333333cm 0cm #4ff5ff;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            margin: 0 1rem;
            position: relative;
            right: -2rem;
        }
        article:nth-child(2n+1)::before {
            content: "\2022";
            color: white;
            background: white;
            border-radius: 50%;
            border: 7px solid var(--color-blue);
            box-shadow: 0cm 0cm 0.7408333333cm 0cm #4ff5ff;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            margin: 0 1rem;
            position: relative;
            left: -1.7rem;
            top: 0.3rem;
        }
        article:nth-child(2n+1)::after {
            display: none;
        }
    }
    .cennik {
        --padding-x: 1rem;
        --padding-y: 1rem;
    }
}


/* Desktops and laptops ----------- */

@media only screen and (min-width: 1224px) {
    .about-us .doradca {
        background: url(/assets/img/about-us-bg.svg) no-repeat;
        background-size: contain;
        background-position: bottom;
        div:last-child {
            padding-left: 1rem;
        }
        div:last-child {
            right: 10%;
            bottom: 15%;
        }
    }
}


/* Desktops and laptops ----------- */

@media only screen and (min-width: 1536px) {
    .about-us {
        .header {
            left: -5rem;
        }
    }
}


/* Large screens ----------- */

@media only screen and (min-width: 1824px) {
    /* Styles */
}

#cookieConsentModal {
    z-index: 11;
    .cookie-consent-modal .modal-content-wrap .modal-content .modal-footer .buttons .btn.btn-primary {
        background-color: var(--color-blue);
    }
    .cookie-consent-modal .modal-content-wrap .modal-content .modal-footer .buttons .btn.btn-primary:hover {
        background-color: var(--color-blue2);
    }
}